import { useEffect, useState } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useBackendClient } from '../hooks/useBackendClient';
import NavBar from '../Components/navbar';

export const PrivateRoutes = () => {

  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  const backendAPI = useBackendClient();

  const validateJWT = async () => {
    setLoading(true);
    let response = await backendAPI.validateJWT();
    if (response.status === 200) {
      setAuthenticated(true);
    }
    else if (response.status === 401) {
      console.error("401 error caught")
      setAuthenticated(false);
    }
    else {
      setAuthenticated(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    validateJWT();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!authenticated) {
    console.error("Not authenticated")
    return <Navigate to='/' />
  }

  return (
    <div>
      <NavBar />
      <Outlet />
    </div>
  )
}

