import React, { useEffect, useState } from "react";

export default function PreFabMap(props: any) {

    const [mapURL, setMapURL] = useState<string>("");

    useEffect(() => {
        if (props.start == null || props.end == null) {
            return;
        }

        let mapLink = "";

        switch (props.start) {
            case "Health Sciences":
                switch (props.end) {
                    case "Towers":
                        mapLink = "https://www.google.com/maps/d/u/0/embed?mid=1YozMbJJdpdkdNjuziJi73Yj2vct2v5U&ehbc=2E312F";
                        break;
                    case "Engineering":
                        mapLink = "https://www.google.com/maps/d/u/0/embed?mid=1gBLXwxFuUxdIJ806E_y2NCnP1_dmimw&ehbc=2E312F";
                        break;
                    case "Beechurst":
                        mapLink = "link_for_HealthSciences_to_Beechurst";
                        break;
                    case "Walnut":
                        mapLink = "link_for_HealthSciences_to_Walnut";
                        break;
                    default:
                        mapLink = "invalid_end_location";
                }
                break;
            case "Towers":
                switch (props.end) {
                    case "Health Sciences":
                        mapLink = "link_for_Towers_to_HealthSciences";
                        break;
                    case "Engineering":
                        mapLink = "link_for_Towers_to_Engineering";
                        break;
                    case "Beechurst":
                        mapLink = "link_for_Towers_to_Beechurst";
                        break;
                    case "Walnut":
                        mapLink = "link_for_Towers_to_Walnut";
                        break;
                    default:
                        mapLink = "invalid_end_location";
                }
                break;
            case "Engineering":
                switch (props.end) {
                    case "Health Sciences":
                        mapLink = "link_for_Engineering_to_HealthSciences";
                        break;
                    case "Towers":
                        mapLink = "link_for_Engineering_to_Towers";
                        break;
                    case "Beechurst":
                        mapLink = "link_for_Engineering_to_Beechurst";
                        break;
                    case "Walnut":
                        mapLink = "link_for_Engineering_to_Walnut";
                        break;
                    default:
                        mapLink = "invalid_end_location";
                }
                break;
            case "Beechurst":
                switch (props.end) {
                    case "Health Sciences":
                        mapLink = "link_for_Beechurst_to_HealthSciences";
                        break;
                    case "Towers":
                        mapLink = "link_for_Beechurst_to_Towers";
                        break;
                    case "Engineering":
                        mapLink = "link_for_Beechurst_to_Engineering";
                        break;
                    case "Walnut":
                        mapLink = "link_for_Beechurst_to_Walnut";
                        break;
                    default:
                        mapLink = "invalid_end_location";
                }
                break;
            case "Walnut":
                switch (props.end) {
                    case "Health Sciences":
                        mapLink = "link_for_Walnut_to_HealthSciences";
                        break;
                    case "Towers":
                        mapLink = "link_for_Walnut_to_Towers";
                        break;
                    case "Engineering":
                        mapLink = "link_for_Walnut_to_Engineering";
                        break;
                    case "Beechurst":
                        mapLink = "link_for_Walnut_to_Beechurst";
                        break;
                    default:
                        mapLink = "invalid_end_location";
                }
                break;
            default:
                mapLink = "invalid_start_location";
        }

        setMapURL(mapLink);
    }, [props.start, props.end]);

    return (
        <React.Fragment>
            {mapURL !== "" && <iframe src={mapURL} width="640" height="480"></iframe>}
        </React.Fragment>
    )
}
