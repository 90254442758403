import * as React from 'react';
import { useState } from "react";
import { Modal, Card, Typography, Box, TextField, FormControlLabel, Checkbox } from "@mui/material";
import { CustomButton } from "../custombutton";
import { ReactStateAction } from '../../Types';
import { useBackendClient } from "../../hooks/useBackendClient";
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import Alert, { AlertColor } from '@mui/material/Alert';

interface AddUserModalProps {
    open: boolean
    modalSet: ReactStateAction<string>
}

export default function AddUserModal({ open, modalSet }: AddUserModalProps) {
    const backendClient = useBackendClient()
    const [usernameState, setUsernameState] = useState('')
    const [passwordState, setPasswordState] = useState('')
    const [confirmPasswordState, setConfirmaPasswordState] = useState('')
    const [isAdminState, setIsAdminState] = useState(false)

    const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUsernameState(event.target.value);
    };

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPasswordState(event.target.value);
    };

    const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmaPasswordState(event.target.value);
    };

    const handleAdminChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsAdminState(event.target.checked);
    };

    const handleCreateUser = async () => {
        if (passwordState !== confirmPasswordState) {
            //Insert snackbar
            setSnackbarMessage("Passwords do not match")
            setSnackbarSeverity("error")
            setOpenSnackbar(true)
            return
        }
        const response = await backendClient.createUser(usernameState, passwordState, isAdminState ? 'true' : 'false')
        if (response.status === 200) {
            //Insert snackbar
            setSnackbarMessage("User Created")
            setSnackbarSeverity("success")
            setOpenSnackbar(true)
            return
        }
        else if (response.status === 409) {
            //Insert snackbar
            setSnackbarMessage("User already exists")
            setSnackbarSeverity("error")
            setOpenSnackbar(true)
            return
        }
        else {
            //Insert snackbar
            setSnackbarMessage("Error creating user")
            setSnackbarSeverity("error")
            setOpenSnackbar(true)
            return
        }
    };

    const handleClose = () => {
        modalSet('none')
    }

    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState('');
    const [snackbarSeverity, setSnackbarSeverity] = React.useState<AlertColor>('success');
    const closeSnackbar = (
        event?: React.SyntheticEvent | Event,
        reason?: SnackbarCloseReason,
    ) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackbar(false);
    };

    return (
        <Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Card sx={{ backgroundColor: '#eaaa00', padding: '2ch', borderRadius: '15px', width: '300px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>
                    <Typography variant="h5" gutterBottom><strong>Create User</strong></Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, margin: '0 2ch 2ch' }}>
                        <TextField label="Username" name="username" value={usernameState} onChange={handleUsernameChange} />
                        <TextField label="Password" name="password" value={passwordState} onChange={handlePasswordChange} />
                        <TextField label="Reenter Password" name="reenterPassword" value={confirmPasswordState} onChange={handleConfirmPasswordChange} />
                        <FormControlLabel control={<Checkbox checked={isAdminState} onChange={handleAdminChange} />} label="Admin Account" />
                    </Box>
                    <CustomButton type="submit" onClick={handleCreateUser}>Add User</CustomButton>
                </Card>
            </Modal>
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={closeSnackbar}>
                <Alert
                    onClose={closeSnackbar}
                    severity={snackbarSeverity}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    )
}
