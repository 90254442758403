import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridColumnVisibilityModel } from '@mui/x-data-grid';
import { useBackendClient } from '../../hooks/useBackendClient';
import Button from '@mui/material/Button';

const columns: GridColDef<any>[] = [
    { field: 'carNumber', headerName: 'Car Number', width: 100 },
    { field: 'trip', headerName: 'Trip', width: 100 },
    { field: 'date', headerName: 'Date', width: 100 },
    { field: 'start', headerName: 'Start', width: 100 },
    { field: 'end', headerName: 'End', width: 100 },
    { field: 'distance', headerName: 'Distance', type: 'number', width: 100 },
    { field: 'start_time', headerName: 'Start Time', width: 100 },
    { field: 'end_time', headerName: 'End Time', width: 100 },
    { field: 'energyConsumption', headerName: 'Energy Consumption', type: 'number', width: 100 },
    { field: 'passengerNum', headerName: 'Passenger Number', type: 'number', width: 100 },
    { field: 'highestEnergy', headerName: 'Highest Energy', type: 'number', width: 100 },
    { field: 'HET', headerName: 'HET', type: 'number', width: 100 },
];

export default function DataGridDemo() {
    const backendClient = useBackendClient();
    const [rows, setRows] = React.useState<any[]>([]);
    let i = 0;

    const [visibility, setVisibility] = React.useState<GridColumnVisibilityModel>({
        id: true,
        carNumber: true,
        trip: true,
        date: true,
        start: true,
        end: true,
        distance: true,
        start_time: true,
        end_time: true,
        energyConsumption: true,
        passengerNum: true,
        highestEnergy: true,
        HET: true,
    });

    const fetchData = async () => {
        const response = await backendClient.getData();
        const row = response.data.map((subArray: any[]) => ({
            id: i++,
            carNumber: subArray[0],
            trip: subArray[1],
            date: subArray[2],
            start: subArray[3],
            end: subArray[4],
            distance: subArray[5],
            start_time: subArray[6],
            end_time: subArray[7],
            energyConsumption: subArray[8],
            passengerNum: subArray[9],
            highestEnergy: subArray[10],
            HET: subArray[11],
        }));
        setRows(row);
    }

    const checkColumns = async () => {
        const isIdNull = rows.every((row) => row.id !== null);
        const isCarNumberNull = rows.every((row) => row.carNumber !== null);
        const isTripNull = rows.every((row) => row.trip !== null);
        const isDateNull = rows.every((row) => row.date !== null);
        const isStartNull = rows.every((row) => row.start !== null);
        const isEndNull = rows.every((row) => row.end !== null);
        const isDistanceNull = rows.every((row) => row.distance !== null);
        const isStartTimeNull = rows.every((row) => row.start_time !== null);
        const isEndTimeNull = rows.every((row) => row.end_time !== null);
        const isEnergyNull = rows.every((row) => row.energyConsumption !== null);
        const isPassengerNumNull = rows.every((row) => row.passengerNum !== null);
        const isHighestEnergyNull = rows.every((row) => row.highestEnergy !== null);
        const isHETNull = rows.every((row) => row.HET !== null);

        setVisibility({
            id: isIdNull,
            carNumber: isCarNumberNull,
            trip: isTripNull,
            date: isDateNull,
            start: isStartNull,
            end: isEndNull,
            distance: isDistanceNull,
            start_time: isStartTimeNull,
            end_time: isEndTimeNull,
            energyConsumption: isEnergyNull,
            passengerNum: isPassengerNumNull,
            highestEnergy: isHighestEnergyNull,
            HET: isHETNull,
        });
    }

    React.useEffect(() => {
        fetchData();
        checkColumns();
    }, []);

    const downloadCSV = () => {
        //Download csv file
        // Convert the data array to a CSV string
        const csvContent = rows.map(row => row.join(",")).join("\n");

        // Create a Blob from the CSV string
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

        // Create a link element
        const link = document.createElement("a");

        // Create a URL for the Blob and set it as the href attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);

        // Set the download attribute with the filename
        link.setAttribute("download", 'data.csv');

        // Append the link to the body
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Remove the link from the document
        document.body.removeChild(link);
    }

    const uploadFile = async () => {
        //Upload txt file
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = '.txt';
        fileInput.click();
        fileInput.onchange = async () => {
            const file = fileInput.files?.[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = async (event) => {
                    try {
                        const fileText = event.target?.result as string;
                        const response = await backendClient.uploadData(fileText);
                    } catch (error) {
                        console.error(error);
                    }
                }
            }
        };
    }

    return (
        <Box sx={{ width: '100%' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                pageSizeOptions={[5]}
                checkboxSelection
                disableRowSelectionOnClick
                columnVisibilityModel={visibility}
            />
            <Button variant="contained" onClick={downloadCSV}> Download </Button>
            <Button variant="contained" onClick={uploadFile}> Upload File </Button>
        </Box>
    );
}

