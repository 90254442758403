import { LineChart } from '@mui/x-charts/LineChart';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const otherSetting = {
  height: 300,
  yAxis: [{ label: 'Volts' }],
  grid: { horizontal: true },
};

export default function MyLineChart(yLabel: string, xLabel: string, chartTitle: string, xData: string[], yData: number[]) {

  // console.log(xData);

  //Case where no data is present
  if (xData === undefined || yData === undefined || xData.length === 0 || yData.length === 0) {
    console.log("No Data for line chart.");
    return (
      <Box>
        <Typography variant="h4" gutterBottom>
          {chartTitle}
        </Typography>
        <Typography variant="h6" gutterBottom>
          No Data
        </Typography>
      </Box>
    )
  }

  return (
    <LineChart
      width={500}
      height={300}
      series={[
        { data: yData, label: chartTitle, color: '#0e5e99' },
      ]}
      xAxis={[{ scaleType: 'point', data: xData.map((_, i) => i.toString()), label: xLabel }]}
      yAxis={[{ label: yLabel }]}
    />
  );
}


