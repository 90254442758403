import * as React from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import IconButton from '@mui/material/IconButton';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import InputAdornment from '@mui/material/InputAdornment';

export default function PasswordBox({ label, name }: { label: string, name: string }) {
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    //Removes second visibility icon from ms edge browser *NOTE* SX DOES NOT SUPPORT -ms-reveal (psuedo-element)**
    const msCss = `
    ::-ms-reveal {
            display: none;
        
        }
    `;

    return (
        <Box sx={{ display: 'flex', alignItems: 'flex-end', pb: 2, justifyContent: "center" }}>
            <style>{msCss}</style>
            <FormControl sx={{ mx: 2, width: '35ch' }} variant="standard">
                <InputLabel htmlFor="standard-adornment-password">{label}</InputLabel>
                <Input
                    id="standard-adornment-password"
                    name={name}
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>
        </Box>
    );
}