import React from 'react';
import {
	Alert,
	alpha,
	Box,
	Button,
	CircularProgress,
	Paper,
	Skeleton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	useTheme,
} from '@mui/material';
import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { CloudUpload, Delete, PlayArrow } from '@mui/icons-material';
import { useBackendClient } from '../hooks/useBackendClient';

// Table Skeleton
const TableSkeletonRow = () => (
	<TableRow>
		<TableCell>
			<Skeleton animation="pulse" />
		</TableCell>
		<TableCell>
			<Skeleton animation="pulse" />
		</TableCell>
		<TableCell>
			<Skeleton animation="pulse" />
		</TableCell>
	</TableRow>
);

// Function to execute python scripts
// TODO: Actually pull result from db for provided script (currently does nothing with provided script)
const executeScript = async (file: File) => {
	return new Promise<any[]>((resolve) => {
		setTimeout(() => {
			resolve([
				{ id: 1, name: 'Data Point 1', value: Math.floor(Math.random() * 100) + 1 },
				{ id: 2, name: 'Data Point 2', value: Math.floor(Math.random() * 100) + 1 },
				{ id: 3, name: 'Data Point 3', value: Math.floor(Math.random() * 100) + 1 },
				{ id: 4, name: 'Data Point 4', value: Math.floor(Math.random() * 100) + 1 },
			]);
		}, 1500);
	});
};

function AnalysisPage() {
	const theme = useTheme();
	const [file, setFile] = useState<File | null>(null);
	const [fileContents, setFileContents] = useState<string | null>(null);

	const [isExecuting, setIsExecuting] = useState<boolean>(false);
	const [error, setError] = useState<string | null>(null);

	const [currentTable, setCurrentTable] = useState<any[][][] | null>(null);
	const [currentPage, setCurrentPage] = useState(0);
	const [tables, setTables] = useState<any[][][] | null>(null);
	const [tableSize, setTableSize] = useState(0);

	//Build Table
	const buildTable = (titles: any[][], data: any[][]) => {
		if (!data) return;

		const tables = [];

		for (let i = 0; i < titles.length; i += 1) {
			const cutTitles = [];

			for(let j = 0; j < data[i][0].length; j += 1) {
				cutTitles.push(titles[i][j])
			}

			tables.push([cutTitles, data[i]])
		}

		setTableSize(tables.length - 1)
		setTables(tables);
		setCurrentTable(tables[0]);
	}

	//For Pagination
	const handlePrevPage = () => {
		if (tables === null) return
		setCurrentPage(Math.max(0, currentPage - 1))
	}

	const handleNextPage = () => {
		if (tables === null) return
		setCurrentPage(Math.min(currentPage + 1, tables.length - 1))
	}

	//PAGE CHANGING
	React.useEffect(() => {
		console.log("Changing page: " + currentPage)
		if (tables === null) return
		setCurrentTable(tables[currentPage])
	}, [currentPage])


	// READ FILE FUNCTION
	React.useEffect(() => {
		const readFile = () => {
			if (file) {
				const reader = new FileReader();
				reader.onload = (e) => {
					if (e.target?.result) {
						const contents = e.target.result as string
						setError(null)
						setFileContents(contents)
					}
				};
				reader.readAsText(file)
			}
		};
		if (file) {
			readFile()
		}
	}, [file]);

	React.useEffect(() => {
		if (fileContents !== null) uploadData(fileContents)
	}, [fileContents])

	const downloadCSV = () => {
		// Download csv file
		// Convert the data array to a CSV string
		if (currentTable === null) return

		const csvContent = currentTable[0].join(",").concat("\n", currentTable[1].map(row => row.join(",")).join("\n"));

		// Create a Blob from the CSV string
		const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

		// Create a link element
		const link = document.createElement("a");

		// Create a URL for the Blob and set it as the href attribute
		const url = URL.createObjectURL(blob);
		link.setAttribute("href", url);

		// Set the download attribute with the filename
		link.setAttribute("download", 'data.csv');

		// Append the link to the body
		document.body.appendChild(link);

		// Programmatically click the link to trigger the download
		link.click();

		// Remove the link from the document
		document.body.removeChild(link);

	}

	//Calling API endpoint
	const backendClient = useBackendClient();
	const [_, setAdminFlag] = useState(false)

	//Function to check Admin privilege	when page loads
	React.useEffect(() => {
		const isAdmin = async () => {
			await backendClient.isAdmin().then((response) => {
				if (response === 'true') {
					setAdminFlag(true)
				}
			})
		}
		isAdmin()
	}, [])

	//Uploading data from file
	const uploadData = (data: string | null) => {
		console.log("ON UPLOADING DATA")
		console.log("FILE CONTENTS: " + fileContents)
		checkScript()
		return //response
	}

	//Set restrictions for non-admin users
	const checkScript = () => {
		console.log("ON CHECK SCRIPT")
		console.log("FILE CONTENTS: " + fileContents)
		//Read data to check script is acceptable
		if (fileContents === null) {
			setIsExecuting(false);
			setError('Invalid Script. Script is null.')
			return false
		}
		if (fileContents) {
			const temp = fileContents.toLowerCase()
			if (temp.includes('users')) {
				setIsExecuting(false);
				setError('Invalid script. Can Only read from final_data and ride_data tables.')
				return false
			}
			else if (temp.includes('delete')) {
				setIsExecuting(false);
				setError('Invalid script. Can Only read from final_data and ride_data tables.')
				return false
			}
			else if (temp.includes('update')) {
				setIsExecuting(false);
				setError('Invalid script. Can Only read from final_data and ride_data tables.')
				return false
			}
			else if (temp.includes('insert')) {
				setIsExecuting(false);
				setError('Invalid script. Can Only read from final_data and ride_data tables.')
				return false
			}
			else if (temp.includes('merge')) {
				setIsExecuting(false);
				setError('Invalid script. Can Only read from final_data and ride_data tables.')
				return false
			}
			return true
		}
	}

	// Function To Handle File Drag and Drop
	const onDrop = useCallback((acceptedFiles: File[], fileRejections: any[]) => {
		if (acceptedFiles.length > 0) {
			setFile(acceptedFiles[0]);
			setError(null);
		} else if (fileRejections.length > 0) {
			//setIsExecuting(false);
			setError('Invalid file type. Please upload a Text file (.txt).');
		}
	}, []);

	// Setup react-dropzone
	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		accept: {
			'text/plain': ['.txt'],
		},
		multiple: false,
	});

	// Function to handle running the python script
	const handleScriptRun = async () => {
		if (fileContents === null) return

		if (file) {
			console.log("SCRIPT RUNNING")
			setIsExecuting(true);

			//Upload Script
			const response = await backendClient.uploadScript(fileContents)
			console.log(response)
			console.log(response)

			if (!response) {
				setIsExecuting(false);
				setError('Script Unable to Execute. Please try again.')
				return
			}

			console.log("DATA")
			console.log(response.data)
			console.log("Titles")
			console.log(response.tables)
			let titles = response.titles
			const data = response.data

			buildTable(titles, data)

			setIsExecuting(false)
		}
	};

	// Remove uploaded file
	const handleRemove = () => {
		setFile(null);
		setError(null);
		setFileContents(null)
		setTables(null)
		setCurrentPage(0)
		setCurrentTable(null)
	};

	return (

		<Box sx={{ maxWidth: 800, margin: 'auto', padding: 4 }}>
			{/* TOP DRAG AND DROP SECTION */}
			<Paper
				elevation={0}
				sx={{
					padding: 4,
					marginBottom: 4,
					borderRadius: 4,
					backgroundColor: alpha(theme.palette.primary.contrastText, 0.8),
					border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
				}}
			>
				{/* Display Any Errors */}
				{error && (
					<Alert severity="error" sx={{ marginBottom: 2 }}>
						{error}
					</Alert>
				)}

				{/* If there is no file display the drag and drop area */}
				{!file ? (
					<Box
						sx={{
							border: `2px dashed ${alpha(theme.palette.primary.main, 0.5)}`,
							borderRadius: 4,
							textAlign: 'center',
							padding: 4,
							cursor: 'pointer',
							transition: 'all 0.3s ease',
							'&:hover': {
								backgroundColor: alpha(theme.palette.primary.main, 0.1),
							},
						}}
						{...getRootProps()}
					>
						<input {...getInputProps()} />
						{isDragActive ? (
							<Typography variant="h6" color="primary.dark">
								Drop the script here ...
							</Typography>
						) : (
							<Box>
								<CloudUpload sx={{ fontSize: 48, color: theme.palette.primary.main }} />
								<Typography variant="h6" color="primary.dark">
									Drag and drop a .txt doc script here, or click to browse files.
									<br></br>
									Separate SQL queries on txt doc with semicolons.
								</Typography>
							</Box>
						)}
					</Box>
				) : (
					<Box textAlign="center">
						<Typography variant="h6" gutterBottom color="primary.main">
							{file.name} - {file.size / 1000} KB
						</Typography>

						<Box sx={{ '& > button': { margin: 1 } }}>
							<Button
								variant="contained"
								color="primary"
								onClick={handleScriptRun}
								disabled={isExecuting}
								startIcon={isExecuting ? <CircularProgress size={24} color="inherit" /> : <PlayArrow />}
							>
								{isExecuting ? 'Running...' : 'Run Script'}
							</Button>
							<Button onClick={handleRemove} variant="outlined" color="error" startIcon={<Delete />}>
								Remove
							</Button>
						</Box>
					</Box>
				)}
			</Paper>

			{/* TABLE RESULTS SECTION */}
			<Paper
				elevation={0}
				sx={{
					padding: 4,
					borderRadius: 2,
					backgroundColor: alpha(theme.palette.primary.contrastText, 0.8),
					border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
				}}
			>
				<Typography variant="h5" gutterBottom color="primary.dark">
					Script Results
				</Typography>
				<TableContainer>
					<Table>
						<TableHead>
							{/*Assign Table Headers, they are the odd indices of the results*/}
							{currentTable && file ? (
								<TableRow>
									{currentTable[0].map((title, index) => (
										<TableCell sx={{ color: theme.palette.primary.main, fontWeight: 'bolder' }}>{title}</TableCell>
									))}
								</TableRow>
							) : (
								<TableSkeletonRow />
							)}
						</TableHead>

						<TableBody>
							{isExecuting || (!currentTable && file) ? (
								// Skeleton rows while loading results
								<>
									<TableSkeletonRow />
									<TableSkeletonRow />
									<TableSkeletonRow />
								</>
							) : currentTable && !isExecuting ? (
								// Actual Results
								// Assign Table Data, they are the even indices of the results
								currentTable[1].map((row, index) => (
									<TableRow key={index}>
										{row.map((cell, index) => (
											<TableCell key={index}>{String(cell)}</TableCell>
										))}
									</TableRow>
								))
							) : (
								// No File Uploaded
								<TableRow>
									<TableCell colSpan={3} align="center">
										<Typography variant="body2" color="primary.dark">
											Upload a SQL script and run it to view the results here...
										</Typography>
									</TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
			{/* Buttons for Pagination, and downloading results */}
			<Paper
				elevation={0}
				sx={{
					padding: 4,
					borderRadius: 2,
					backgroundColor: alpha(theme.palette.primary.contrastText, 0.8),
					border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
				}}
			>
				<Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
					<Button onClick={downloadCSV} disabled={currentTable === null}>Download Results</Button>
					<Button onClick={handlePrevPage} disabled={currentPage === 0}>Previous</Button>
					<Button onClick={handleNextPage} disabled={!tables || currentPage === tableSize}>Next</Button>
				</Box>
			</Paper>
		</Box>
	);
}

export default AnalysisPage;
