import React from 'react';
import DemoPaper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import PasswordBox from '../Components/passwordbox';
import { CustomButton } from '../Components/custombutton';
import { LoginBackground } from '../Components/loginbackground';
import { LoginCard } from '../Components/logincard';
import Typography from '@mui/material/Typography';
import { useBackendClient } from '../hooks/useBackendClient';
import { useNavigate } from 'react-router-dom';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { CircularProgress } from '@mui/material';


export default function Login() {

  const backendAPI = useBackendClient()
  const navigate = useNavigate()
  const [_, setFlag] = React.useState(true)
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false)

  const handleClose = (
    _?: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setIsLoading(true)
    event.preventDefault()
    const formData = new FormData(event.currentTarget)
    const username = formData.get('username') as string
    const password = formData.get('password') as string

    const response = await backendAPI.login(username, password);

    if (response.status === 200) {
      setFlag(true)
      localStorage.setItem('jwt', response.data.access);
      backendAPI.setJwt(response.data.access);
      if (localStorage.getItem('jwt') !== null) {
        navigate('/home');
      }
    }
    else {
      setIsLoading(false)
      setOpen(true)
      setFlag(false)

    }
  }

  return (
    <LoginBackground>
      <LoginCard>
        <DemoPaper sx={{ pt: 1, pb: 1 }} square={true} elevation={0}>
          <Typography sx={{ fontSize: 'larger', pb: 2 }}><strong>Welcome to the WVU PRT Monitoring App!</strong></Typography>
          <form onSubmit={handleSubmit}>
            <Box sx={{ display: 'flex', alignItems: 'flex-end', mx: 2, mb: 2 }}>
              <TextField sx={{ width: '35ch', autocomplete: 'username' }} id="input-with-sx" label="Username" variant="standard" name="username" />
            </Box>
            <PasswordBox label="Password" name="password"></PasswordBox>
            {
              isLoading ? <CircularProgress color="inherit" /> : <CustomButton type="submit">Login</CustomButton>
            }
          </form>

          <br></br>
          {/*<Link href="/forgot-password"> Forgot Password </Link>*/}
        </DemoPaper>
      </LoginCard>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Invalid Username or Password.
        </Alert>
      </Snackbar>
    </LoginBackground>
  );
}