import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import LineChart from '../Components/linechart';
import Typography from '@mui/material/Typography';
import { useBackendClient } from "../hooks/useBackendClient";
import { useLocation } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { Stack } from '@mui/material';
import PreFabMap from '../Components/preFabMap';

export default function Prt_Hist() {
  const [isLoading, setIsLoading] = React.useState(true);
  const [data, setData] = React.useState<any[]>([]);
  const [_, setCapacityData] = React.useState<any[]>([]);
  const [dataFlag, setDataFlag] = React.useState(false);

  const backendClient = useBackendClient()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const carID = params.get('id')

  const fetchData = async () => {
    const data = await backendClient.getCarData(carID!)
    if (data.length === 0) {
      setDataFlag(false)
      return
    }
    setDataFlag(true)
    setData(data)
  }

  const fetchCapacityHistory = async (carID: string) => {
    const data = await backendClient.getCapacityHistory(carID)
    setCapacityData(data)
  }

  useEffect(() => {
    fetchData().then(() => {
      fetchCapacityHistory(carID!).then(() => {
        setIsLoading(false)
      })
    })
  }, [])

  if (isLoading) {
    return (
      <Box component="div" sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center", marginTop: "10%" }}>
        <CircularProgress color="inherit" />
      </Box>
    )
  }

  return (
    <Box component="div" sx={{ width: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center', overflowX: 'auto', }}>
      {!dataFlag ? (
        <Typography variant="h3" sx={{ fontweight: "bold", display: "flex", flexWrap: 'wrap' }}>NO DATA FOR CAR {carID}</Typography>
      ) : (
        <Box component="div" sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0 auto', px: '5%', mx: '5%', }}>
          {/* Outer grid component  */}
          <Grid container spacing={2} sx={{ justifyContent: 'center' }}>
            {/*Left side  */}
            <Grid item xs={4}>
              <Stack spacing={2}>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', borderRadius: "15px", boxShadow: "2", backgroundColor: "white", padding: "20px" }}>
                  <Stack spacing={1}>
                    <Typography variant="h5" sx={{ fontWeight: "bold", pb: 1 }}>PRT ({carID}) Current Statistics</Typography>
                    <Typography>
                      <strong>Voltage (kWh): </strong>{data[0][10]}
                    </Typography>
                    <Typography>
                      <strong>Start Platform: </strong>{data[0][2]}
                    </Typography>
                    <Typography>
                      <strong>End Platform: </strong>{data[0][3]}
                    </Typography>
                    <Typography>
                      <strong>Passengers: </strong>{data[0][6]}
                    </Typography>
                  </Stack>
                </Box>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', borderRadius: "15px", boxShadow: "2", backgroundColor: "white", padding: "20px" }}>
                  <PreFabMap start={data[0][2]} end={data[0][3]} />
                </Box>
              </Stack>
            </Grid>

            {/* Right side  */}
            <Grid item xs={6}>
              <Stack spacing={2}>
              <Box sx ={{display:'flex', flexWrap:'wrap', justifyContent: 'center', borderRadius:"15px", boxShadow:"2", backgroundColor :"white", padding:"20px"} }>
                {LineChart ("Voltage (kWh)", "Time (min)", "Voltage vs Time", data.map(row => row[7]), data.map(row => row[10]))}
                </Box>

                <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', borderRadius: "15px", boxShadow: "2", backgroundColor: "white", padding: "20px" }}>
                  {LineChart("Speed (MPH)", "Time (min)", "Speed vs Time", data.map(row => row[8]), data.map(row => row[12]))}
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
}
