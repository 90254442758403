import React from 'react';
import DemoPaper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import PasswordBox from '../Components/passwordbox';
import { CustomButton } from '../Components/custombutton';
import { LoginBackground } from '../Components/loginbackground';
import { LoginCard } from '../Components/logincard';
import Typography from '@mui/material/Typography';
import { useBackendClient } from "../hooks/useBackendClient";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export default function SignUp() {

  const [checked, setChecked] = React.useState(false);

  const handleChange = () => {
    setChecked(!checked);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const username = formData.get('username') as string;
    const password = formData.get('password') as string;
    const reenterPassword = formData.get('reenterPassword') as string;
  };

  const backendAPI = useBackendClient();

  const signUp = async (username: string, password: string) => {
    try {
      const response = await backendAPI.createUser(username, password, "true");
      return response;
    }
    catch (error) {
      console.error(error);
    }
  }

  return (
    <LoginBackground>
      <LoginCard>
        <DemoPaper sx={{ pt: 1, pb: 1 }} square={true} elevation={0}>
          <Typography sx={{ fontSize: 'larger', pb: 2 }}><strong>Create User</strong></Typography>
          <form onSubmit={handleSubmit}>
            <Box sx={{ display: 'flex', alignItems: 'flex-end', mx: 2, mb: 2 }}>
              <TextField sx={{ width: '35ch' }} id="input-with-sx" label="Username" variant="standard" name="username" />
            </Box>
            <PasswordBox label="Password" name="password"></PasswordBox>
            <PasswordBox label="Reenter Password" name="reenterPassword"></PasswordBox>
            <FormControlLabel control={<Checkbox checked={checked} onChange={() => handleChange()} />} label="Admin Account" />
            <CustomButton type="submit">Sign Up</CustomButton>
          </form>
        </DemoPaper>
      </LoginCard>
    </LoginBackground>
  );
}