import axios, { AxiosInstance } from "axios";

export class BackendAPI {
    client: AxiosInstance;
    jwt: string | undefined;

    constructor(jwt?: string) {
        this.jwt = jwt;
        this.client = axios.create({
            baseURL: "https://dev.prt-capstone.podcake.net/",
            headers: {
                'Content-Type': 'application/json',
                'Content-Security-Policy': 'upgrade-insecure-requests',
                Authorization: jwt ? `Bearer ${jwt}` : ''
            }
        });
    }

    resetJwt = () => {
        this.jwt = undefined
        this.client.defaults.headers.Authorization = '';
    }

    setJwt = (jwt: string | undefined) => {
        this.jwt = jwt
        this.client.defaults.headers.Authorization = jwt ? `Bearer ${jwt}` : ''
    }

    //Api calls will go here
    //That way we dont need to send headers in every call
    //Just make calls like: this.client.get('/api/users')

    getData = () => {
        return this.client.get<any>('test')
            .then((response) => {
                return Promise.resolve(response);
            }).catch((error) => {
                console.error("Promise Error:", error)
                return Promise.reject(error);
            })
    }

    login = async (username: string, password: string): Promise<any> => {
        try {
            const response = await this.client.post<any>('/api/signIn', {
                username: username,
                password: password
            });
            //Set the JSON web token in local storage
            return response;
        } catch (error) {
            const typedError = error as any;
            if (typedError.response) {
                // Server responded with a status other than 2xx
                console.error("Server error:", typedError.response.data);
            } else if (typedError.request) {
                // Request was made but no response received
                console.error("Network error:", typedError.request);
            } else {
                // Something else happened
                console.error("Error:", typedError.message);
            }
            return error;
        }
    }

    createUser = async (username: string, password: string, adminFlag: string): Promise<any> => {
        try {
            const response = await this.client.post<any>('/api/signUp', { username, password, adminFlag });
            return response;
        } catch (error) {
            return error;
        }
    }

    forgotPassword = (username: string) => {
        return this.client.post<any>('forgotpassword', { username })
            .then((response) => {
                return response;
            }).catch((error) => {
                return error;
            })
    }

    addCart = async (carsToAdd: string) => {
        try {
            const response = await this.client.post<any>('/api/setWatchedCar', { carsToAdd });
            return response;
        } catch (error) {
            return error;
        }
    }

    removeCart = async (carsToRemove: string) => {
        try {
            const response = await this.client.post<any>('/api/removeWatchedCar', { carsToRemove });
            return response;
        } catch (error) {
            return error;
        }
    }

    getWatchList = async () => {
        try {
            const response = await this.client.get<any>('api/getWatchedCarsList');
            return response.data;
        } catch (error) {
            return error;
        }
    }

    getWatchListData = async () => {
        try {
            const response = await this.client.get<any>('api/getWatchedCarsData');
            return response.data;
        } catch (error) {
            return error;
        }
    }

    getCarData = async (carID: string) => {
        try {
            const response = await this.client.post<any>('/api/getCarData', { carID });
            return response.data;
        } catch (error) {
            return error;
        }
    }

    getCars = async () => {
        try {
            const response = await this.client.get<any>('api/getCars');
            return response.data;
        } catch (error) {
            return error;
        }
    }

    validateJWT = async () => {
        return this.client.get<any>('api/validateJWT')
            .then((response) => {
                return Promise.resolve(response);
            }).catch((error) => {
                return error;
            })
    }

    uploadData = async (file: any) => {
        try {
            const formData = new FormData();
            formData.append('file', file);
            const response = await this.client.post<any>('/api/uploadRawData', formData);
            return response;
        } catch (error) {
            return error;
        }
    }

    isAdmin = async () => {
        try {
            const response = await this.client.get<any>('api/isAdmin');
            return response.data;
        } catch (error) {
            return error;
        }
    }

    getCapacityHistory = async (carNumber: String) => {
        try {
            const response = await this.client.post<any>('api/getCapacityHistory', { carNumber });
            return response.data;
        } catch (error) {
            return error;
        }
    }

    uploadScript = async (script: string) => {
        try {
            const response = await this.client.post<any>('api/uploadScript', { script });
            return response.data;
        } catch (error) {
            return null;
        }
    }
}