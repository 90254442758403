import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import HomeBack from '../Images/Home_Background.png'

export const HomeBackground = styled(Box)({
    backgroundImage: `url(${HomeBack})`,
    height: '100vh',
    width: '100vw',
    backgroundPosition: 'center',
    backgroundRepeat: 'repeat-y',
    backgroundSize: 'cover',
    overflowY: 'auto',
    overflowX: 'auto',
});