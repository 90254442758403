import NavBar from '../Components/navbar';
import Box from '@mui/material/Box';
import { HomeBackground } from '../Components/homebackground';
import DemoDataGrid from '../Components/Tables/DemoDatagrid';

export default function Prt_Hist() {

  return (
    <HomeBackground>
      <NavBar />
      <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center", marginTop: "20px", }}>
        <Box sx={{ backgroundColor: "white", width: "80%", padding: "20px", boarderRadius: "15px" }}>
          {/* MUIX datagrid  */}
          <DemoDataGrid />
        </Box>
      </Box>
    </HomeBackground>
  );
}