import './App.css';
import { Route, Routes } from 'react-router-dom';
import { PrivateRoutes } from './Services/RouteGuard';
import { ThemeProvider } from '@mui/material';
import { BackendAPI } from './Services/BackendAPI';
import { createContext, useMemo } from 'react';
import { defaultTheme } from './Services/ThemeProvider';
import LoginPage from './Pages/login';
import HomePage from './Pages/home';
import PrtHist from './Pages/PRT_History';
import WatchList from './Pages/watchlist';
import SignUp from './Pages/signup';

import TestDataBaseQuery from './TestDatabaseQuery';
import Demo from './Pages/demo';
import AnalysisPage from './Pages/analysis';
import LidarPage from './Pages/lidar';
import { HomeBackground } from './Components/homebackground';

export const BackendClientContext = createContext<BackendAPI>({} as BackendAPI);

function App() {
	const jwt = localStorage.getItem('jwt') || '';

	const BackendClient = useMemo(() => {
		return new BackendAPI(jwt);
	}, []);

	return (
		<div>
			{/*<div>This is where the app bar should go so it is loaded regardless of route</div>*/}
			{/* Wrap app in backendClientContext so all the components can access the backend client */}
			<BackendClientContext.Provider value={BackendClient}>
				{/* The app also needs to be wrapped in a theme provider using our default theme to see the standard colors we set */}
				<ThemeProvider theme={defaultTheme}>
					<HomeBackground>
						<Routes>
							{/* This section is where private routes will go. Basically the routes that require authentication. These are just examples and need to be replaced*/}
							{/* Right route guard just returns true since we have no way of authenticating */}
							<Route element={<PrivateRoutes />}>

								<Route path="/car" element={<PrtHist />} />
								<Route path="/home" element={<HomePage />} />
								<Route path="/testDatabase" element={<TestDataBaseQuery />} />
								<Route path="demo" element={<Demo />} />
								<Route path="/watchlist" element={<WatchList />} />
								{/*<Route path="/anomalies" element={<AnomaliesPage />} />*/}
								<Route path="/analysis" element={<AnalysisPage />} />
								{/*<Route path="/myprofile" element={<MyProfile />} />*/}
								<Route path="/signup" element={<SignUp />} />
								<Route path="/lidar" element={<LidarPage />} />
							</Route>
							{/* This section is where public routes will go since it is outside of the route guard. Any route that does not require auth */}
							{/*<Route path = "/?" element={<Navigate to = "/home" />}/>*/}
							<Route path="" element={<LoginPage />} />
						</Routes>
					</HomeBackground>
				</ThemeProvider>
			</BackendClientContext.Provider>
		</div>
	);
}
export default App;
