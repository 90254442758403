import { Box, Typography } from '@mui/material';
import { HomeBackground } from '../Components/homebackground';

// Interface for 2D point
interface Point {
	x: number;
	y: number;
}

// Lidar Constants

function LidarPage() {
	return (
		<HomeBackground>
			<Box>
				<Typography variant="h4">Lidar Page</Typography>
			</Box>
		</HomeBackground>
	);
}

export default LidarPage;
