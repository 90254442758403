import React from 'react';
import { useEffect } from 'react';
import Box from '@mui/material/Box';
import PrtCard from '../Components/prt_card';
import { CircularProgress, TextField } from '@mui/material';
import { useBackendClient } from '../hooks/useBackendClient';

type PRTList = {
  id: number,
  voltage: number,
  location: string,
  station: string,
  occupancy: number
}

export default function Home() {

  const [searchBarValue, setSearchBarValue] = React.useState("");
  const [watchedCars, setWatchedCars] = React.useState<number[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const _ = React.useState<any[][]>([[]]);
  const backendAPI = useBackendClient();

  const prtList: PRTList[] = [
    { id: 1, voltage: 234, location: "Location 1", station: "Station 1", occupancy: 45 },
    { id: 2, voltage: 567, location: "Location 2", station: "Station 2", occupancy: 32 },
    { id: 3, voltage: 789, location: "Location 3", station: "Station 3", occupancy: 67 },
    { id: 4, voltage: 123, location: "Location 4", station: "Station 4", occupancy: 12 },
    { id: 5, voltage: 456, location: "Location 5", station: "Station 5", occupancy: 78 },
    { id: 6, voltage: 890, location: "Location 6", station: "Station 6", occupancy: 23 },
    { id: 7, voltage: 345, location: "Location 7", station: "Station 7", occupancy: 56 },
    { id: 8, voltage: 678, location: "Location 8", station: "Station 8", occupancy: 89 },
    { id: 9, voltage: 901, location: "Location 9", station: "Station 9", occupancy: 34 },
    { id: 10, voltage: 234, location: "Location 10", station: "Station 10", occupancy: 45 },
    { id: 11, voltage: 567, location: "Location 11", station: "Station 11", occupancy: 32 },
    { id: 12, voltage: 789, location: "Location 12", station: "Station 12", occupancy: 67 },
    { id: 13, voltage: 123, location: "Location 13", station: "Station 13", occupancy: 12 },
    { id: 14, voltage: 456, location: "Location 14", station: "Station 14", occupancy: 78 },
    { id: 15, voltage: 890, location: "Location 15", station: "Station 15", occupancy: 23 },
    { id: 16, voltage: 345, location: "Location 16", station: "Station 16", occupancy: 56 },
    { id: 17, voltage: 678, location: "Location 17", station: "Station 17", occupancy: 89 },
    { id: 18, voltage: 901, location: "Location 18", station: "Station 18", occupancy: 34 },
    { id: 19, voltage: 234, location: "Location 19", station: "Station 19", occupancy: 45 },
    { id: 20, voltage: 567, location: "Location 20", station: "Station 20", occupancy: 32 },
    { id: 21, voltage: 789, location: "Location 21", station: "Station 21", occupancy: 67 },
    { id: 22, voltage: 123, location: "Location 22", station: "Station 22", occupancy: 12 },
    { id: 23, voltage: 456, location: "Location 23", station: "Station 23", occupancy: 78 },
    { id: 24, voltage: 890, location: "Location 24", station: "Station 24", occupancy: 23 },
    { id: 25, voltage: 345, location: "Location 25", station: "Station 25", occupancy: 56 },
    { id: 26, voltage: 678, location: "Location 26", station: "Station 26", occupancy: 89 },
    { id: 27, voltage: 901, location: "Location 27", station: "Station 27", occupancy: 34 },
    { id: 28, voltage: 234, location: "Location 28", station: "Station 28", occupancy: 45 },
    { id: 29, voltage: 567, location: "Location 29", station: "Station 29", occupancy: 32 },
    { id: 30, voltage: 789, location: "Location 30", station: "Station 30", occupancy: 67 },
    { id: 31, voltage: 123, location: "Location 31", station: "Station 31", occupancy: 12 },
    { id: 32, voltage: 456, location: "Location 32", station: "Station 32", occupancy: 78 },
    { id: 33, voltage: 890, location: "Location 33", station: "Station 33", occupancy: 23 },
    { id: 34, voltage: 345, location: "Location 34", station: "Station 34", occupancy: 56 },
    { id: 35, voltage: 678, location: "Location 35", station: "Station 35", occupancy: 89 },
    { id: 36, voltage: 901, location: "Location 36", station: "Station 36", occupancy: 34 },
    { id: 37, voltage: 234, location: "Location 37", station: "Station 37", occupancy: 45 },
    { id: 38, voltage: 567, location: "Location 38", station: "Station 38", occupancy: 32 },
    { id: 39, voltage: 789, location: "Location 39", station: "Station 39", occupancy: 67 },
    { id: 40, voltage: 123, location: "Location 40", station: "Station 40", occupancy: 12 },
    { id: 41, voltage: 456, location: "Location 41", station: "Station 41", occupancy: 78 },
    { id: 42, voltage: 890, location: "Location 42", station: "Station 42", occupancy: 23 },
    { id: 43, voltage: 345, location: "Location 43", station: "Station 43", occupancy: 56 },
    { id: 44, voltage: 678, location: "Location 44", station: "Station 44", occupancy: 89 },
    { id: 45, voltage: 901, location: "Location 45", station: "Station 45", occupancy: 34 },
    { id: 46, voltage: 234, location: "Location 46", station: "Station 46", occupancy: 45 },
    { id: 47, voltage: 567, location: "Location 47", station: "Station 47", occupancy: 32 },
    { id: 48, voltage: 789, location: "Location 48", station: "Station 48", occupancy: 67 },
    { id: 49, voltage: 123, location: "Location 49", station: "Station 49", occupancy: 12 },
    { id: 50, voltage: 456, location: "Location 50", station: "Station 50", occupancy: 78 },
    { id: 51, voltage: 890, location: "Location 51", station: "Station 51", occupancy: 23 },
    { id: 52, voltage: 345, location: "Location 52", station: "Station 52", occupancy: 56 },
    { id: 53, voltage: 678, location: "Location 53", station: "Station 53", occupancy: 89 },
    { id: 54, voltage: 901, location: "Location 54", station: "Station 54", occupancy: 34 },
    { id: 55, voltage: 234, location: "Location 55", station: "Station 55", occupancy: 45 },
    { id: 56, voltage: 567, location: "Location 56", station: "Station 56", occupancy: 32 },
    { id: 57, voltage: 789, location: "Location 57", station: "Station 57", occupancy: 67 },
    { id: 58, voltage: 123, location: "Location 58", station: "Station 58", occupancy: 12 },
    { id: 59, voltage: 456, location: "Location 59", station: "Station 59", occupancy: 78 },
    { id: 60, voltage: 890, location: "Location 60", station: "Station 60", occupancy: 23 },
    { id: 61, voltage: 345, location: "Location 61", station: "Station 61", occupancy: 56 },
    { id: 62, voltage: 678, location: "Location 62", station: "Station 62", occupancy: 89 },
    { id: 63, voltage: 901, location: "Location 63", station: "Station 63", occupancy: 34 },
    { id: 64, voltage: 234, location: "Location 64", station: "Station 64", occupancy: 45 },
    { id: 65, voltage: 567, location: "Location 65", station: "Station 65", occupancy: 32 },
    { id: 66, voltage: 789, location: "Location 66", station: "Station 66", occupancy: 67 },
    { id: 67, voltage: 123, location: "Location 67", station: "Station 67", occupancy: 12 },
    { id: 68, voltage: 456, location: "Location 68", station: "Station 68", occupancy: 78 },
    { id: 69, voltage: 890, location: "Location 69", station: "Station 69", occupancy: 23 },
  ]

  useEffect(() => {
    const fetchWatchedCars = async () => {
      try {
        const response = await backendAPI.getWatchList();

        let watchedCars: any[] = [];

        response.forEach((car: string) => watchedCars.push(parseInt(car)))

        setWatchedCars(watchedCars)
        setIsLoading(false)

      } catch (error) {
        console.error('Error fetching watched cars:', error);
      }

    };

    fetchWatchedCars();

  }, []);

  if (isLoading) {
    return (
      <Box component="div" sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center", marginTop: "10%" }}>
        <CircularProgress color="inherit" />
      </Box>
    )
  }

  return (
    <Box>
      <Box component="div" sx={{ display: "flex", justifyContent: "center", paddingTop: "10px" }}>
        <TextField defaultValue={""} value={searchBarValue} onChange={(e) => setSearchBarValue(e.target.value)} id="outlined-basic" label="Search Car #" variant="outlined" sx={{ backgroundColor: "white", borderRadius: "5px" }} />
      </Box>

      <Box component="div" sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
        {
          searchBarValue !== "" ?
            prtList.filter(item => parseInt(item.id as unknown as string, 10) === parseInt(searchBarValue as string, 10)).map(item => {
              return <PrtCard key={item.id} id={item.id} voltage={item.voltage} location={item.location} station={item.station} occupancy={item.occupancy} watched={watchedCars.includes(item.id)} />;
            }) :

            //FUNCTION for mapping hardcoding prtList
            prtList.map(item => {
              return <PrtCard key={item.id} id={item.id} voltage={item.voltage} location={item.location} station={item.station} occupancy={item.occupancy} watched={watchedCars.includes(item.id)} />;
            })
        }
      </Box>
    </Box>
  );
}

