import { styled } from '@mui/system';
import Box from '@mui/material/Box';

export const LoginCard = styled(Box)({
    backgroundColor: '#eaaa00',
    paddingTop: '2ch',
    paddingBottom: '2ch',
    borderRadius: '15px',
    opacity: '0.60',
    width: '300px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    alignContent: 'center',
    textAlign: 'center',
    '&:hover': {
        opacity: '1'
    }
});