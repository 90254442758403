import { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ListIcon from '@mui/icons-material/List';
import Box from '@mui/material/Box';
import DoneIcon from '@mui/icons-material/Done';
import { Link as RouterLink } from 'react-router-dom';
import { useBackendClient } from '../hooks/useBackendClient';

type PRTCardProps = {
  id: number,
  voltage: number,
  location: string,
  station: string,
  occupancy: number,
  watched: boolean
}

export default function Prt_Card({ id, voltage, location, station, occupancy, watched }: PRTCardProps) {

  const [color, setColor] = useState("#002856")
  const [icon, setIcon] = useState(<ListIcon />)
  const [hover, setHover] = useState("#eaaa00")
  const [text, setText] = useState("ADD")
  const [state, setState] = useState(false)

  const backendAPI = useBackendClient()

  useEffect(() => {
    if (watched) {
      setColor("#a7001a")
      setIcon(<ListIcon />)
      setHover("#650103")
      setText("REMOVE")
      setState(true)
    }
  }, [watched])


  const removeFromList = async (id: number) => {
    await backendAPI.removeCart(id.toString())
    return
  }

  const addToList = async (id: number) => {
    await backendAPI.addCart(id.toString())
    return
  }

  const handleClick = async () => {
    if (state) {
      await removeFromList(id)
      setColor("green")
      setIcon(<DoneIcon />)
      setHover("green")
      setText("REMOVED")
      setState(false)

      setTimeout(() => {
        setColor("#002856")
        setIcon(<ListIcon />)
        setHover("#eaaa00")
        setText("ADD")
      }, 1000)
    }
    else {
      await addToList(id)
      setColor("green")
      setIcon(<DoneIcon />)
      setHover("green")
      setText("ADDED")
      setState(true)

      setTimeout(() => {
        setColor("#a7001a")
        setIcon(<ListIcon />)
        setHover("#650103")
        setText("REMOVE")
      }, 1000)
    }
  };

  return (
    <Box component="div" sx={{ pl: 1, pr: 1, m: 1, display: "flex", flexWrap: "auto", borderRadius: "15px", backgroundColor: "#eaaa00", justifyContent: "center" }} width="250px">
      <Card sx={{ width: "230px", color: "#002856" }} square={true} elevation={0}>
        <CardMedia
          sx={{ height: 100, backgroundColor: "gray" }}
          image={require('../Images/PRT-icon.png')}
          title="PRT"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            <strong>PRT:</strong> {id}
          </Typography>
          <Typography variant="body2" color="#002856" sx={{ textAlign: "left" }}>
            <strong>Current Power:</strong> {voltage}
            <br></br>
            <strong>Start Platform:</strong> {location}
            <br></br>
            <strong>End Platform:</strong> {station}
            <br></br>
            <strong>Passenger #:</strong> {occupancy}
          </Typography>
        </CardContent>
        <CardActions>
          <Button component={RouterLink} to={`/car?id=${id}`} size="small" variant="contained" sx={{ backgroundColor: "#002856", ml: "auto", mr: "auto", "&:hover": { backgroundColor: "#eaaa00" } }}>History</Button>
          <Button size="small" variant="contained" sx={{ ml: "auto", mr: "auto", backgroundColor: color, "&:hover": { backgroundColor: hover } }} onClick={handleClick} endIcon={icon}>{text}</Button>
        </CardActions>
      </Card>
    </Box>
  );
}