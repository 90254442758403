import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PrtCard from '../Components/prt_card';
import { useBackendClient } from "../hooks/useBackendClient";
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';


export default function WatchList() {
  const [hasItems, setHasItems] = useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [watchList, setWatchList] = useState<any[]>([]);
  const [searchBarValue, setSearchBarValue] = React.useState("");

  const backendAPI = useBackendClient();

  const getList = async () => {
    try {
      const response = await backendAPI.getWatchListData()
      const watchList = Array.isArray(response) ? response : []
      setWatchList(watchList)
      console.log("WATCHLIST")
      console.log(watchList)
      return response
    } catch (error) {
      console.error('Error fetching watchlist: ', error)
      setWatchList([])
      return null
    }
  }

  useEffect(() => {
    const fetchList = async () => {
      const list = await getList()
      if (list.length > 0) {
        setHasItems(true)
      }
      setIsLoading(false)
    }
    fetchList()

  }, []) // Empty dependency array ensures this runs only once

  return (
    <Box>
      <Box component="div" sx={{ display: "flex", justifyContent: "center", paddingTop: "10px" }}>
        <TextField defaultValue={""} value={searchBarValue} onChange={(e) => setSearchBarValue(e.target.value)} id="outlined-basic" label="Search Car #" variant="outlined" sx={{ backgroundColor: "white", borderRadius: "5px" }} />
      </Box>
      <Box component="div" sx={{ display: "flex", flexWrap: "wrap", textAlign: "center", justifyContent: "center" }}>
        {isLoading ? (
          <CircularProgress color="inherit" />
        ) : hasItems ? (

          searchBarValue !== "" ?
            watchList.filter(list => parseInt(list[0] as unknown as string, 10) === parseInt(searchBarValue as string, 10)).map(list => {
              return <PrtCard key={list[0]} id={list[0]} voltage={list[10]} location={list[2]} station={list[3]} occupancy={list[6]} watched={true} />;
            }) :

            watchList.map((list) => {
              if (list == null) return null
              return <PrtCard key={list[0]} id={list[0]} voltage={list[10]} location={list[2]} station={list[3]} occupancy={list[6]} watched={true} />;
            })

        ) : (
          <Typography variant="h3" sx={{ fontweight: "bold", display: "flex", flexWrap: 'wrap' }}>NOTHING IN WATCHLIST</Typography>
        )}
      </Box>
    </Box>
  );
}